<template>
  <v-dialog v-if="visible"
            v-model="visible"
            @click:outside="closeDialog"
            @keydown.esc="closeDialog">
    <v-card>
      <v-card-title class="card-title elevation-3">
        Заявка на возврат #{{ current.id }} от {{ current.createdDate | format }}
        <v-btn absolute right icon @click="closeDialog">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="mt-5">
        <v-container fluid>
          <v-row>
            <v-col cols="4">
              <v-text-field readonly label="Заявка" v-model="current.id" />
              <v-text-field readonly label="Продавец" v-model="current.supplier.name" />
              <v-text-field readonly label="Склад Clever" v-model="current.warehouse.name" />
              <v-text-field
                readonly
                label="Ответственный исполнитель продавца"
                v-model="current.supplier.contactPerson"
              />
              <v-text-field readonly label="Email" v-model="current.supplier.email" />
              <v-text-field
                readonly
                label="Контактный телефон"
                v-model="current.supplier.telephone"
              />
              <v-textarea
                readonly
                rows="2"
                auto-grow
                label="Комментарий"
                v-model="current.comment"
              />
              <v-text-field readonly label="Дата отгрузки" :value="current.shipmentDate | format" />
            </v-col>
            <v-col cols="8">
              <v-card elevation="2">
                <v-card-title>
                  Товары
                </v-card-title>
                <v-card-text>
                  <crm-table :headers="headers" :data="current.items"></crm-table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-divider v-if="current.status === 'CREATED'" />
          <v-row class="mt-5" v-if="current.status === 'CREATED'">
            <v-spacer />
            <v-col cols="2">
              <v-btn block color="primary" text @click="decline">ОТКЛОНИТЬ</v-btn>
            </v-col>
            <v-col cols="2">
              <v-btn block color="primary" rounded @click="accept">ПРИНЯТЬ</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
    <v-dialog v-model="acceptDialog" width="400px">
      <v-card>
        <v-card-title>
          Выберите дату отгрузки
        </v-card-title>
        <v-card-text>
          <v-date-picker
            v-model="shipmentDate"
            first-day-of-week="1"
            full-width
            :min="new Date().toISOString().slice(0,10)"
          >
            <template #default>
              <v-row>
                <v-spacer />
                <v-btn color="primary" text @click="acceptDialog = false">Отменить</v-btn>
                <v-btn color="primary" rounded @click="submit">Ок</v-btn>
              </v-row>
            </template>
          </v-date-picker>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import crmTable from '@/components/crm-table/index.vue';
import moment from 'moment';

export default {
  props: {
    visible: {
      default: false,
    },
    item: {
      default: () => {},
    },
  },
  components: {
    'crm-table': crmTable,
  },
  filters: {
    format(val) {
      if (moment(val).isValid()) {
        return moment(val, 'YYYY-MM-DDTHH:mm:ss.sss[Z]').format('DD-MM-YYYY');
      }
      return '';
    },
  },
  data() {
    return {
      headers: [
        {
          value: 'id',
          sortable: false,
          text: 'ID заявки',
          type: 'defaultItem',
        },
        {
          value: 'productBarcode',
          sortable: false,
          text: 'Штрихкод',
          type: 'defaultItem',
        },
        {
          value: 'productNameRu',
          sortable: false,
          text: 'Наименование',
          type: 'defaultItem',
        },
        {
          value: 'productBrand',
          sortable: false,
          text: 'Бренд',
          type: 'defaultItem',
        },
        {
          value: 'productColor.nameRu',
          sortable: false,
          text: 'Цвет',
          type: 'defaultItem',
        },
        {
          value: 'productSize',
          sortable: false,
          text: 'Размер',
          type: 'defaultItem',
        },
        {
          value: 'amount',
          sortable: false,
          text: 'Количество',
          type: 'defaultItem',
        },
      ],
      current: {
        items: [],
      },
      menu2: false,
      shipmentDate: null,
      acceptDialog: false,
      config: {
        search: true,
      },
    };
  },
  watch: {
    item: {
      handler(val) {
        if (val) {
          this.current = { ...val };
        }
      },
      immediate: true,
    },
  },
  methods: {
    closeDialog() {
      this.$emit('closed');
    },
    accept() {
      this.acceptDialog = true;
    },
    allowedDates(val) {
      return ![0, 6].includes(moment(val).day());
    },
    submit() {
      if (this.shipmentDate) {
        const body = {
          id: this.current.id,
          shipmentDate: moment(this.shipmentDate, 'YYYY-MM-DD').format('YYYY-MM-DDTHH:mm:ss.sss[Z]'),
        };
        this.$api.post('/api/v1/wms/product/returns/accept', body).then(() => {
          this.closeDialog();
        });
      }
    },
    decline() {
      const body = {
        id: this.current.id,
      };
      this.$api.post('/api/v1/wms/product/returns/decline', body).then(() => {
        this.closeDialog();
      });
    },
  },
};
</script>

<style scoped>
.card-title {
  height: 70px;
}
</style>
